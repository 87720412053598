.imgCr {
    height: 330px;

}

.csrBg {
    background-color: #1851a014;

}




.csrImg {
    width: 100px;
}