.myAlink {
    text-decoration: none;
    color: #263b5e;
}

.footerColor {
    color: #263b5e;
}

footer {
    background: #fbfbfd;
}

.new_footer_area {
    background: #fbfbfd;
}


.new_footer_top {
    padding: 120px 0px 270px;
    position: relative;
    overflow-x: hidden;
}

.new_footer_area .footer_bottom {
    /* padding-top: 5px; */
    /* padding-bottom: 50px; */
}

.footer_bottom {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #7f88a6;
    padding: 27px 0px;
}

.btn_get_two:hover {
    background: transparent;
    color: #5e2ced;
}

.btn_get:hover {
    color: #fff;
    background: #6754e2;
    border-color: #6754e2;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
    text-decoration: none;
    outline: none;
}





.new_footer_top .f_widget.about-widget .f_list li a:hover {
    color: #5e2ced;
}

.new_footer_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
}

.f_widget.about-widget .f_list li:last-child {
    margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
    margin-bottom: 15px;
}

.f_widget.about-widget .f_list {
    margin-bottom: 0px;
}



.f_social_icon a {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 24px;
    /* line-height: 45px; */
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}



.btn_get_two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #5e2ced;
    border-color: #5e2ced;
    color: #fff;
}

.btn_get_two:hover {
    background: transparent;
    color: #5e2ced;
}

.new_footer_top .f_social_icon a:hover {
    background: #5e2ced;
    border-color: #5e2ced;
    color: white;
}



.f_600 {
    font-weight: 600;
}

.f_size_18 {
    font-size: 18px;
}



.new_footer_top .f_widget.about-widget .f_list li a {
    color: #6a7695;
}


.new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url("../../assets/footerBg.png") no-repeat scroll center 0;
    width: 100%;
    height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
    background: url("../../assets//footerGif/volks.gif") no-repeat center center;
    width: 330px;
    height: 76px;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
    background: url("../../assets/footerGif/cyclist.gif") no-repeat center center;
    width: 88px;
    height: 100px;
    background-size: 100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_three {
    background: url("../../assets/footerGif/Ad-Plane.png") no-repeat center center;
    width: 395px;
    height: 351px;
    background-size: 100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 15s linear infinite;
    animation: myfirst 15s linear infinite;
}




@-moz-keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

@-webkit-keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

@keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

/*************footer End *****************/