.officeBg {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
}

.officeHeading {
    color: white;
    font-size: 32px;
    font-weight: 800;
    line-height: 60px;
}

.accordianHead {
    background-color: goldenrod;
}