.myServiceBg {
    background-color: #0077be;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
    background-size: 60px 60px;
    padding: 0;
    margin: 0;
}


.slide-from-right-to-left {
    position: relative;
    left: 100%;
    animation: slide-left 1s forwards;
}

@keyframes slide-left {
    0% {
        left: 100%;
    }

    100% {
        left: 0;
    }
}


.slide-from-left-to-right {
    position: relative;
    left: -100%;
    animation: slide-right 1s forwards;
}

@keyframes slide-right {
    100% {
        left: 0;
    }
}

.serviceTitle {
    font-family: var(--font-family-primary);
    font-size: 32px;
    line-height: 1.4;
    color: #222022;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background-image: linear-gradient(90deg, #FFEF7E, #B7F9E9);
    background-size: 100% 42%;
    background-repeat: no-repeat;
    background-position: 0 85%;
    padding: 0 4px;
    margin-left: -4px;
}