.myNav {

    text-decoration: none;
    color: #272626;
    text-transform: uppercase;
    padding: 15px 5px;



}

.navBtn {
    border: none;
    background-color: #f8f9fa;
    /* display: inline; */
}

.myNav {
    /* font-family: arial; */
    color: #08181b;
    text-decoration: none;
    position: relative;
    padding: 10px 10px;
    font-size: 13px;
    font-weight: bold;
    /* display: block; */
}


.myNav::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 4px;
    background-color: #1b509f;
    transform-origin: bottom right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
}


.myNav:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
}




.myDropdown {
    padding: 0px;
}


/* gradientLine */