.eventHeading {
    color: #1d50a0;
    font-size: 32px;
    font-weight: 800;
    line-height: 60px;
}

.glow-on-hover {
    width: 100px;
    height: 35px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    /* background: #b93939; */
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}


.glow-on-hover:active {
    color: #000;
    /* color: #fa2a2a */
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1d50a0;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}