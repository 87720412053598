.blog-header {
    line-height: 1;
    border-bottom: 1px solid #e5e5e5;
}

.featured {
    background-size: cover;
    background-repeat: no-repeat;
    text-shadow: 1px 1px 1px black;
}

.blog-header-logo {
    font-family: "Exo 2", "Helvetica", Arial, sans-serif;
    font-size: 2.25rem;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
}

.blog-header-logo:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Exo 2", "Helvetica", Arial, sans-serif;
    font-weight: 800;
}

.display-4 {
    font-size: 2.5rem;
    font-weight: 700;
}

@media (min-width: 768px) {
    .display-4 {
        font-size: 3rem;
    }
}

.blog-post {
    margin-bottom: 4rem;
}

.blog-post-title {
    margin-bottom: .25rem;
    font-size: 2.5rem;
}

.blog-post-meta {
    margin-bottom: 1.25rem;
    color: #727272;
}