.showcase {
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #111;
    transition: 0.5s;
    z-index: 2;
}


.showcase video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #03a9f4;
    mix-blend-mode: overlay;
    margin-top: 19px;
}

.text {
    position: relative;
    z-index: 10;
}

.text h1 {
    font-size: 4em;
    font-weight: 800;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;

}

.text h3 {
    font-size: 3em;
    font-weight: 700;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}

.text p {
    font-size: 1.3rem;
    color: #fff;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
}

.text a {
    display: inline-block;
    font-size: 1em;
    /* background: #fff; */
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
    color: #ffffff;
    letter-spacing: 2px;
    transition: 0.2s;
    /* border: 5px solid rgb(255, 255, 255); */
    /* aita remove*/
}

.text a:hover {
    letter-spacing: 6px;
}

.social {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social li {
    list-style: none;
}

.social li a {
    display: inline-block;
    margin-right: 1px;
    filter: invert(1);
    transform: scale(0.5);
    transition: 0.5s;
}

.social li a:hover {
    transform: scale(0.5) translateY(-15px);
}


@media (max-width: 991px) {

    .showcase,
    .showcase header {
        padding: 40px;
    }

    .text h2 {
        font-size: 3em;
    }

    .text h3 {
        font-size: 2em;
    }
}



.vidHeaderBtn {

    width: 154px;
    height: 51px;
    border: none;
    outline: none;
    color: #fff;
    /* background: #111; */
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;

}


.newVidBtn {
    display: inline-block;
    font-size: 1em;
    /* background: #fff; */
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
    color: #440d0d;
    letter-spacing: 2px;
    transition: 0.2s;
}

/* header video btn */