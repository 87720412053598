.modalTitle {
    font-size: 13px;
}

.popUpBtn {
    width: 61px;
    height: 23px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;

    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 8px;

}