.brkNews {
    background: #ffffff;
}

.news {
    width: 160px;
}

.news-scroll a {
    text-decoration: none;
}

.dot {
    height: 6px;
    width: 6px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px !important;
    background-color: rgb(207, 23, 23);
    border-radius: 50%;
    display: inline-block;
}

.brNewsBg {
    background-color: #1d50a0;
    border-radius: 25px;

}


.newsDateColor {
    color: red;
}


.newsWeight {
    font-size: 18px;
    font-weight: 800;
    color: #6a3783;
}