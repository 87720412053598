.inner-width {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    overflow: hidden;
}

.section-title {
    text-align: center;
    color: #ddd;
    text-transform: uppercase;
    font-size: 30px;
}

.border {
    width: 160px;
    height: 2px;
    background: #82ccdd;
    margin: 40px auto;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.service-box {
    max-width: 33.33%;
    padding: 10px;
    text-align: center;
    color: #ddd;
    cursor: pointer;
}

.service-icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 3px solid #edf2fd;
    color: #edf2fd;
    transform: rotate(0deg);
    margin-bottom: 30px;
    margin-top: 16px;
    transition: 0.3s linear;
}

.service-icon i {
    line-height: 70px;
    transform: rotate(-45deg);
    font-size: 26px;
}

.service-box:hover .service-icon {
    background: #82ccdd;
    color: #ddd;
}

.service-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #c5e2e9;
    font-weight: 600;
}

.service-title-one {
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #c5e2e9;
    font-weight: 800;
}

.service-desc {
    font-size: 14px;
}

@media screen and (max-width:960px) {
    .service-box {
        max-width: 45%;
    }
}

@media screen and (max-width:768px) {
    .service-box {
        max-width: 50%;
    }
}

@media screen and (max-width:480px) {
    .service-box {
        max-width: 100%;
    }
}

.myServiceFont {
    font-size: 65px;
}