@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');



.container {
    max-width: 1230px;
    width: 100%;
}

.formHeader {
    font-weight: 700;
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
}

.headerForm {
    margin-bottom: 80px;
}

#description {
    font-size: 24px;
}

.form-wrapForm {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    max-width: 850px;
    padding: 50px;
    margin: 0 auto;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}

.form-wrapForm:before {
    content: "";
    width: 90%;
    height: calc(100% + 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    top: -30px;
    background: #010066;
    z-index: -1;
    opacity: 0.8;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
}

.form-groupForm {
    margin-bottom: 25px;
}

.form-groupForm>label {
    display: block;
    font-size: 18px;
    color: #000;
}

.custom-control-labelForm {
    color: #000;
    font-size: 16px;
}

.form-controlForm {
    height: 50px;
    background: #ecf0f4;
    border-color: transparent;
    padding: 0 15px;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.form-controlForm:focus {
    border-color: #00bcd9;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

textarea.form-controlForm {
    height: 160px;
    padding-top: 15px;
    resize: none;
}

.btnForm {
    padding: .657rem .75rem;
    font-size: 18px;
    letter-spacing: 0.050em;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-primaryForm {
    color: #fff;
    background-color: #00bcd9;
    border-color: #00bcd9;
}

.btn-primaryForm:hover {
    color: #00bcd9;
    background-color: #ffffff;
    border-color: #00bcd9;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.btn-primaryForm:focus,
.btn-primaryForm.focus {
    color: #00bcd9;
    background-color: #ffffff;
    border-color: #00bcd9;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}

.btn-primaryForm:not(:disabled):not(.disabled):active,
.btn-primaryForm:not(:disabled):not(.disabled).active,
.show>.btn-primaryForm.dropdown-toggle {
    color: #00bcd9;
    background-color: #ffffff;
    border-color: #00bcd9;
}

.btn-primaryForm:not(:disabled):not(.disabled):active:focus,
.btn-primaryForm:not(:disabled):not(.disabled).active:focus,
.show>.btn-primaryForm.dropdown-toggle:focus {
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
}