.whyBest {
    background-color: #E3F2FD;
}


.heading {
    font-size: 32px;
    font-weight: 600;
    color: #3D5AFE;

}

.line1 {
    color: #000;
    font-size: 12px;

}

.line2 {
    color: #000;
    font-size: 12px;

}

.line3 {
    color: #000;
    font-size: 12px;

}

.cards {

    transition: all 0.2s ease;
    cursor: pointer;


}



.cards:hover {

    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
    background-color: #1b509f;
    color: white;
}