.eventRegBtn {
    text-decoration: none;
    background-color: #1e50a0;
    /* font-size: 12px; */
    color: #fff;
}

.eventRegBtn:hover {
    text-decoration: none;
    color: #fff;
}